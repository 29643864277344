import { useState } from "react";

export default () => {
  const [myStyle, setMyStyle] = useState("");
  const [finalStyle, setFinalStyle] = useState({});
  const styleChanged = (e) => {
    setMyStyle(e.target.value);
    let tmpObject = {};
    try {
      let lines = e.target.value.split(";");
      lines.forEach((line) => {
        if (line.length == 0) {
          return;
        }
        let [property, value] = line.split(":");

        tmpObject[property.trim()] = value.trim();
      });

      setFinalStyle(tmpObject);
    } catch (e) {
      // console.log(e);
    }
  };

  return [myStyle, setMyStyle, finalStyle, setFinalStyle, styleChanged];
};
