export default ({ title }) => {
  return (
    <>
      <header>
        <div id="header">
          <h1 id="game-header">MRU & The Pond</h1>
          <h5 id="game-description">Fun Game for the Whole Family</h5>
          <p className="game-instructions">
            Place the MRU logo on the lotus leaf using Flexbox CSS properties.
            Check out{" "}
            <a
              href="https://masoudkarimif.github.io/posts/css-101/#flexbox"
              target="_blank"
            >
              here
            </a>{" "}
            &{" "}
            <a
              href="https://github.com/comp-2511/css-layout#flexbox"
              target="_blank"
            >
              here
            </a>
            .
          </p>
        </div>
      </header>
      <h2 id="challenge-header">{title}</h2>
    </>
  );
};
