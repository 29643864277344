export default () => {
  return (
    <div id="landing-container">
      <figure id="landing-figure">
        <img src="https://res.cloudinary.com/mkf/image/upload/v1686001000/mru-logo_rqbhb3.png" />
      </figure>
      <div id="landing-text">
        <h1 id="landing-header">MRU & The Pond</h1>
        <h5>Fun Game for the Whole Family!</h5>
      </div>
      <a id="start-game-button" href="/first-challenge">
        Start The Game
      </a>
    </div>
  );
};
