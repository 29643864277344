import { Link } from "react-router-dom";
import SidebarHeader from "./SidebarHeader";
import useStyle from "./StyleHook";
import TextArea from "./TextArea";
import { useEffect } from "react";

const Challenge = ({ name, next, previous, numObjects, end }) => {
  const [style, setMyStyle, finalStyle, setFinalStyle, styleChanged] =
    useStyle();
  const empty = [...Array(numObjects).keys()];
  useEffect(() => {
    setMyStyle("");
    setFinalStyle({});
    console.log("hello");
  }, [next, previous]);
  return (
    <div className="App">
      <aside>
        <SidebarHeader title={`${name} Challenge`} />
        {!previous ? (
          <p className="hint">
            Hint: <code>justify-content: flex-end</code>
          </p>
        ) : null}
        <TextArea style={style} styleChanged={styleChanged} />
        <div className="button-holder">
          {previous ? (
            <Link className="page-button" to={`/${previous}-challenge`}>
              Previous Challenge
            </Link>
          ) : null}
          {next ? (
            <Link className="page-button" to={`/${next}-challenge`}>
              Next Challenge
            </Link>
          ) : null}
          {end ? (
            <Link className="page-button" to={`/first-challenge`}>
              Start Over
            </Link>
          ) : null}
        </div>
      </aside>
      <div className="playground">
        <div className="flex-container" style={finalStyle}>
          {empty.map((_, key) => (
            <div className="game-img" src="" alt="ucalgary-logo" key={key} />
          ))}
        </div>
        <div className={`hole-container hole-container-${name.toLowerCase()}`}>
          {empty.map((_, key) => (
            <div className="hole" key={key}></div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Challenge;
