import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LandingPage from "./components/LandingPage";
import Challenge from "./components/Challenge";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />}></Route>
        <Route
          path="/first-challenge"
          element={<Challenge name="First" next="second" numObjects={1} />}
        />
        <Route
          path="/second-challenge"
          element={
            <Challenge
              name="Second"
              next="third"
              previous="first"
              numObjects={1}
            />
          }
        />
        <Route
          path="/third-challenge"
          element={
            <Challenge
              name="Third"
              next="fourth"
              previous="second"
              numObjects={1}
            />
          }
        />
        <Route
          path="/fourth-challenge"
          element={
            <Challenge
              name="Fourth"
              next="fifth"
              previous="third"
              numObjects={2}
            />
          }
        />
        <Route
          path="/fifth-challenge"
          element={
            <Challenge
              name="Fifth"
              next="sixth"
              previous="fourth"
              numObjects={2}
            />
          }
        />
        <Route
          path="/sixth-challenge"
          element={
            <Challenge
              name="Sixth"
              next="seventh"
              previous="fifth"
              numObjects={2}
            />
          }
        />
        <Route
          path="/seventh-challenge"
          element={
            <Challenge
              name="Seventh"
              next="eighth"
              previous="sixth"
              numObjects={2}
            />
          }
        />
        <Route
          path="/eighth-challenge"
          element={
            <Challenge
              name="Eighth"
              next="ninth"
              previous="seventh"
              numObjects={2}
            />
          }
        />
        <Route
          path="/ninth-challenge"
          element={
            <Challenge
              name="Ninth"
              next="tenth"
              previous="eighth"
              numObjects={2}
            />
          }
        />
        <Route
          path="/tenth-challenge"
          element={
            <Challenge
              name="Tenth"
              previous="ninth"
              end={true}
              numObjects={3}
            />
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
